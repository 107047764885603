import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, InputLabel, FormControl, FormHelperText, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import LoadingButton from '@mui/lab/LoadingButton'; 
import Page from '../components/Page';
import { getinvitedetails, MakePaymentLink } from '../apis/generatelink';

const formatPhone = (phone) => {
    phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
        formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
        return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
        formattedPhone = `254${phone}`;
    } else if (phone.startsWith('11') && phone.length === 9) {
        formattedPhone = `254${phone}`;
    } else {
        formattedPhone = null;
    }
    return formattedPhone;
};

export default function PaymentInviteLink() {
    const location = useLocation();
    const [inviteLink, setInviteLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phone, setPhoneNumber] = useState('');
    const [error, setError] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [paymentError, setPaymentError] = useState('');
    const [success, setSuccess] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [response, setResponse] = useState(null); // State to hold response data
    const [taskDetails, setTaskDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const inviteLinkParam = searchParams.get('paymentlink');
        if (!inviteLinkParam) {
            navigate('/login');
        }
        setInviteLink(inviteLinkParam);

        const fetchTaskDetails = async () => {
            try {
                const taskDetails = await getinvitedetails({ invitelink: inviteLinkParam });
                setTaskDetails(taskDetails);
            } catch (error) {
                console.error('Error fetching task details:', error);
            }
        };

        fetchTaskDetails();
    }, [location.search, navigate]);

    const handlePhoneChange = (e) => {
        const { value } = e.target;
        setPhoneNumber(value);
        setPhoneError('');
    };

    const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
        <div style={{ textAlign: 'center' }}>
          <IoMdAlert size={24} color="red" />
          <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
          <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>{paymentError}</Typography>
          <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Try Again
          </Button>
        </div>
    );

    const handleSuccessButtonClick = () => {
        setSuccess(false); // Set success state back to false
        navigate('/staff-dashboard/app', { replace: true });
    };
    

    const ProcessingComponent = () => (
        // View when processing
        <Container style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh', // Ensures the container takes the full height of the viewport
        }}>
          <div style={{ textAlign: 'center' }}>
            <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
            <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Processing Payment...
            </Typography>
          </div>
        </Container>
      );  
      
      const SuccessComponent = ({ response, handleSuccessButtonClick }) => (
        <div style={{ textAlign: 'center' }}>
          <Checkmark size='96px' color='green' />
          {/* <Typography variant="body1" style={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Deposit successful!</Typography> */}
          <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Transaction Id: {response.RotsiAPITransactionId}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            ResponseMessage: {response.CustomerMessage}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            Service: {response.Service}
          </Typography>
          <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
            Return to Dashboard
          </Button>
        </div>
      );  
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const formattedPhoneNumber = formatPhone(phone);
            if (formattedPhoneNumber === null) {
                setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
                setIsSubmitting(false);
                return;
            }

            const requestBody = { 
                amount: Math.trunc(taskDetails.amount), 
                phone: formattedPhoneNumber,
                inviteLink
            };
            console.log(requestBody);
            const response = await MakePaymentLink(requestBody);
            setSuccess(true);
            setResponse(response);
            toast.success('Payment Confirmed!');
        } catch (error) {
            if(error.message){
                setError(true);
                setPaymentError(error.message);
            } else {
                setError(true);
                setPaymentError('Withdrawal failed. Please try again later.');
            }
        } finally {
            setIsSubmitting(false);
            setIsEditing(false);
        }
    };

    return (
        <Page title="Rotsi Generated Payment Link Invite">
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    padding: '20px',
                }}
            >
                {isSubmitting && <ProcessingComponent />}
                {success && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />}
                {paymentError && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={() => setPaymentError('')} />}
                
                {!paymentError && !isSubmitting && !success && taskDetails && (
                    <form onSubmit={handleFormSubmit} style={{ width: '100%', maxWidth: '600px' }}>
                      <InputLabel 
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                              Payment Initiated By
                      </InputLabel>
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                          <TextField
                              variant="outlined"
                              value={taskDetails?.initiatorname || ''}
                              InputProps={{ readOnly: true }}
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                              fullWidth
                          />
                      </FormControl>
  
                      <InputLabel 
                          sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                          Amount Expected to Pay
                      </InputLabel>
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                          <TextField
                              variant="outlined"
                              value={taskDetails?.amount || ''}
                              InputProps={{ readOnly: true }}
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                              fullWidth
                          />
                      </FormControl>
  
                      <InputLabel 
                          sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                          Reason for Payment
                      </InputLabel>
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                          <TextField
                              variant="outlined"
                              value={taskDetails?.paymentreason || ''}
                              InputProps={{ readOnly: true }}
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                              fullWidth
                          />
                      </FormControl>
  
                      <InputLabel 
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                              Your Phone Number
                      </InputLabel>
                      <FormControl fullWidth sx={{ marginBottom: 2 }}>
                          <TextField
                              label="Your Phone Number"
                              variant="outlined"
                              placeholder="2547XXXXXXXX"
                              value={phone}
                              onChange={handlePhoneChange}
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                              fullWidth
                          />
                          {phoneError && (
                              <FormHelperText error sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                  {phoneError}
                              </FormHelperText>
                          )}
                      </FormControl>
                      
                      <FormControl fullWidth sx={{ marginTop: 2 }}>
                          <LoadingButton
                              type="submit"
                              variant="contained"
                              loading={isSubmitting}
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                          >
                              Make Payment
                          </LoadingButton>
                      </FormControl>
                  </form>
                )}
            </Container>
        </Page>
    );
}